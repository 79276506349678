import { confirmDialog } from '@geeckocom/core-ui';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { useCallback } from 'react';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { authModule } from '@/entities/authorization';
import { useApi } from '@/hooks/useApi';
import { CURRENT_PROFILE_QUERY_KEY } from '@/queries/auth/useCurrentProfile';
import { CURRENT_USER_QUERY_KEY } from '@/queries/auth/useCurrentUser';

const useLogout = (): UseMutationResult => {
  const api = useApi();
  const router = useRouter();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(() => api.post('/logout'), {
    onSuccess: async () => {
      dispatch(authModule.actions.setIsTransition(true));
      await Promise.all([
        queryClient.refetchQueries(CURRENT_USER_QUERY_KEY),
        queryClient.refetchQueries(CURRENT_PROFILE_QUERY_KEY),
      ]);
      await router.push('/');
    },
  });
};

export const useLogoutDialog = () => {
  const t = useTranslations();
  const logout = useLogout();

  const openLogoutDialog = useCallback(() => {
    confirmDialog({
      message: t('features.logout.confirmation_message'),
      title: t('features.logout.title'),
      confirmButton: t('features.logout.confirm'),
      cancelButton: t('features.logout.cancel'),
      onConfirm: async () => {
        await logout.mutate({});
      },
    });
  }, [t, logout]);

  return { openLogoutDialog };
};
