import { FC } from 'react';

interface Props {
  size?: number;
}

export const ResumeIcon: FC<Props> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 5H7.5C7.10218 5 6.72064 5.1475 6.43934 5.41005C6.15804 5.6726 6 6.0287 6 6.4V17.6C6 17.9713 6.15804 18.3274 6.43934 18.5899C6.72064 18.8525 7.10218 19 7.5 19H16.5C16.8978 19 17.2794 18.8525 17.5607 18.5899C17.842 18.3274 18 17.9713 18 17.6V9.2L13.5 5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 6V9H17" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 13H9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 15.5H9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.5 9.90002H9.75H9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
