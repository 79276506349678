import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';

interface Props {
  type?: 'ghost' | 'squared';
  size?: number;
}

export const ColorThemeSwitcher: FC<Props> = ({ type = 'ghost', size = 32 }) => {
  const THEME_DATA_ATTRIBUTE = 'data-geecko-theme';
  const storageKey = 'darkMode';
  enum COLOR_THEME {
    DARK = 'dark',
    LIGHT = 'light',
  }

  const [colorTheme, setColorTheme] = useState<string | null>(COLOR_THEME.DARK);

  useEffect(() => {
    const currentTheme = document.body.getAttribute(THEME_DATA_ATTRIBUTE) as COLOR_THEME;
    setColorTheme(currentTheme);
  }, []);

  const handleSwitchColorTheme = () => {
    const currentTheme = document.body.getAttribute(THEME_DATA_ATTRIBUTE);
    const updatedColorTheme = currentTheme === COLOR_THEME.LIGHT ? COLOR_THEME.DARK : COLOR_THEME.LIGHT;
    document.body.setAttribute(THEME_DATA_ATTRIBUTE, updatedColorTheme);
    const isDarkMode = updatedColorTheme === COLOR_THEME.DARK;
    try {
      localStorage.setItem(storageKey, JSON.stringify(isDarkMode));
    } catch (e) {
      //
    }
    setColorTheme(updatedColorTheme);
  };

  return (
    <button
      type="button"
      className={clsx(
        `w-${size / 4}`,
        `h-${size / 4}`,
        'text-text-secondary',
        'hover:text-text-primary',
        type === 'squared' && 'bg-surface-two hover:bg-surface-three rounded',
      )}
      onClick={handleSwitchColorTheme}
    >
      {colorTheme === COLOR_THEME.DARK ? (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto"
        >
          <g clipPath="url(#clip0_117_6762)">
            <path
              d="M7.99984 11.3333C9.84079 11.3333 11.3332 9.84091 11.3332 7.99996C11.3332 6.15901 9.84079 4.66663 7.99984 4.66663C6.15889 4.66663 4.6665 6.15901 4.6665 7.99996C4.6665 9.84091 6.15889 11.3333 7.99984 11.3333Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 0.666626V1.99996" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 14V15.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M2.81348 2.81335L3.76014 3.76002"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.2402 12.24L13.1869 13.1867"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M0.666504 8H1.99984" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 8H15.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M2.81348 13.1867L3.76014 12.24"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.2402 3.76002L13.1869 2.81335"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_117_6762">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto"
        >
          <path
            d="M13.9999 8.52667C13.8951 9.66147 13.4692 10.7429 12.7721 11.6445C12.075 12.5461 11.1356 13.2305 10.0637 13.6177C8.99188 14.0049 7.83192 14.0787 6.7196 13.8307C5.60728 13.5827 4.5886 13.023 3.78275 12.2172C2.97691 11.4113 2.41723 10.3927 2.16921 9.28033C1.92118 8.16801 1.99508 7.00806 2.38224 5.9362C2.7694 4.86434 3.45382 3.92491 4.35541 3.22784C5.257 2.53076 6.33847 2.10487 7.47327 2C6.80888 2.89884 6.48917 4.0063 6.57229 5.12094C6.65541 6.23559 7.13584 7.28337 7.9262 8.07373C8.71656 8.86409 9.76435 9.34452 10.879 9.42765C11.9936 9.51077 13.1011 9.19106 13.9999 8.52667V8.52667Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </button>
  );
};
