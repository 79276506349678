import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { FC } from 'react';

import { useChatra } from '@/shared/hooks/use-chatra';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '@/utils/constants';

export const Footer: FC = () => {
  const t = useTranslations();
  const { openChatra } = useChatra();

  return (
    <footer className="geecko-profile-layout__footer">
      <div className="geecko-profile-layout__footer-wrapper geecko-profile-layout__wrapper geecko-profile-layout__wrapper--footer">
        <Link href="/">
          <div className="logotype" />
        </Link>
        <div className="geecko-profile-layout__footer-wrapper-info">
          <div className="geecko-profile-layout__footer-wrapper-info-address">{t('profile.legalInfo')}</div>
          <div className="geecko-profile-layout__footer-wrapper-info-right">
            <div className="geecko-profile-layout__footer-wrapper-info-attachments">
              <div className="geecko-profile-layout__footer-wrapper-info-attachment">
                <button type="button" onClick={openChatra} className="underline">
                  {t('profile.help')}
                </button>
              </div>
              <div className="geecko-profile-layout__footer-wrapper-info-attachment">
                <a href={TERMS_OF_USE_LINK} rel="noreferrer noopener" target="_blank">
                  {t('profile.termsOfUse')}
                </a>
              </div>
              <div className="geecko-profile-layout__footer-wrapper-info-attachment">
                <a href={PRIVACY_POLICY_LINK} rel="noreferrer noopener" target="_blank">
                  {t('profile.privacyPolicy')}
                </a>
              </div>
            </div>
            <div className="geecko-profile-layout__footer-wrapper-language" />
          </div>
        </div>
      </div>
    </footer>
  );
};
