import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authModule } from '@/entities/authorization';
import { selectCurrentSection } from '@/store';

export const useSetCurrentSection = (section?: string): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authModule.actions.setCurrentSection(section));

    return () => {
      dispatch(authModule.actions.setCurrentSection(undefined));
    };
  }, [section]);
};

export const useGetCurrentSection = (): string => useSelector(selectCurrentSection);
