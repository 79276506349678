import * as Chatra from '@chatra/chatra';
import { useCallback } from 'react';

const config = {
  ID: 'tErhC3R9HAYsJG4no',
  language: 'en',
};

export const useChatra = () => {
  const initChatra = () => {
    Chatra('init', config);
  };

  const openChatra = useCallback(() => {
    initChatra();
    Chatra('openChat', true);
  }, []);

  return { openChatra };
};
