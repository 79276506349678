import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { FC, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { Arrow, useLayer } from 'react-laag';

import { ColorThemeSwitcher } from '@/features/color-theme-switcher';
import { useLogoutDialog } from '@/features/logout';
import { useCurrentUser } from '@/queries/auth/useCurrentUser';
import { shouldCertificatesPageBeVisible, shouldJobsPageBeVisible } from '@/shared/flags';
import { useGetCurrentSection } from '@/shared/hooks/use-current-section';
import { CertificatesIcon } from '@/shared/icons/CertificatesIcon';
import { CloseIcon } from '@/shared/icons/CloseIcon';
import { JobsIcon } from '@/shared/icons/JobsIcon';
import { KeyIcon } from '@/shared/icons/KeyIcon';
import { LogoutIcon } from '@/shared/icons/LogoutIcon';
import { ProfileIcon } from '@/shared/icons/ProfileIcon';
import { ResumeIcon } from '@/shared/icons/ResumeIcon';
import { SettingsIcon } from '@/shared/icons/SettingsIcon';
import { AVATAR_EMPTY } from '@/utils/constants';

import styles from './header.module.scss';

export const Header: FC = () => {
  const user = useCurrentUser();
  const router = useRouter();
  const t = useTranslations();
  const { openLogoutDialog } = useLogoutDialog();

  const currentSection = useGetCurrentSection();

  const avatarStyle = useMemo(
    () => ({
      backgroundImage: `url("${user?.data?.photoUrl || AVATAR_EMPTY}")`,
    }),
    [user?.data?.photoUrl],
  );

  const [isOpen, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: handleClose,
    onDisappear: handleClose,
    overflowContainer: true,
    auto: true,
    placement: 'bottom-end',
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 16,
  });

  return (
    <div className={styles.header}>
      <div className="h-full flex px-4 justify-between items-center lg:px-10">
        <div className="flex">
          <Link href="/">
            <div className="logotype" />
          </Link>
          <div className="hidden md:block md:items-center">
            {user.data ? (
              <div className="geecko-profile-layout__menu-wrapper geecko-profile-layout__wrapper geecko-profile-layout__wrapper--wide lg:px-5">
                <Link href="/profile">
                  <a
                    className={clsx(
                      'geecko-profile-layout__menu-wrapper-link',
                      router.pathname === '/profile' && 'geecko-profile-layout__menu-wrapper-link--active',
                    )}
                  >
                    <ProfileIcon size={24} />
                    <div className="geecko-profile-layout__menu-wrapper-link-title m-l-8">{t('profile.profile')}</div>
                  </a>
                </Link>
                <Link href="/resume/">
                  <a
                    className={clsx(
                      'geecko-profile-layout__menu-wrapper-link',
                      currentSection === 'resume' && 'geecko-profile-layout__menu-wrapper-link--active',
                    )}
                  >
                    <ResumeIcon size={24} />
                    <div className="geecko-profile-layout__menu-wrapper-link-title m-l-8">{t('profile.resume')}</div>
                  </a>
                </Link>
                {shouldCertificatesPageBeVisible ? (
                  <Link href="/certificates">
                    <a
                      className={clsx(
                        'geecko-profile-layout__menu-wrapper-link',
                        currentSection === 'certificates' && 'geecko-profile-layout__menu-wrapper-link--active',
                      )}
                    >
                      <CertificatesIcon size={24} />
                      <div className="geecko-profile-layout__menu-wrapper-link-title m-l-8">
                        {t('profile.certificates')}
                      </div>
                    </a>
                  </Link>
                ) : null}
                {shouldJobsPageBeVisible ? (
                  <Link href="/jobs">
                    <a
                      className={clsx(
                        'geecko-profile-layout__menu-wrapper-link',
                        currentSection === 'jobs' && 'geecko-profile-layout__menu-wrapper-link--active',
                        'mr-0',
                      )}
                    >
                      <JobsIcon size={24} />
                      <div className="geecko-profile-layout__menu-wrapper-link-title m-l-8">{t('profile.jobs')}</div>
                    </a>
                  </Link>
                ) : null}
              </div>
            ) : null}
            {user.isLoading ? (
              <div className="flex space-x-4 items-center h-full">
                <ContentLoader
                  backgroundColor="var(--surface-muted)"
                  foregroundColor="var(--surface-default)"
                  width="100"
                  height="24"
                  viewBox="0 0 100 24"
                  fill="none"
                >
                  <rect width="100" height="24" rx="8" />
                </ContentLoader>
                <ContentLoader
                  backgroundColor="var(--surface-muted)"
                  foregroundColor="var(--surface-default)"
                  width="80"
                  height="24"
                  viewBox="0 0 80 24"
                  fill="none"
                >
                  <rect width="80" height="24" rx="8" />
                </ContentLoader>
                <ContentLoader
                  backgroundColor="var(--surface-muted)"
                  foregroundColor="var(--surface-default)"
                  width="100"
                  height="24"
                  viewBox="0 0 100 24"
                  fill="none"
                >
                  <rect width="100" height="24" rx="8" />
                </ContentLoader>
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex items-center gap-2">
          {user.isLoading ? (
            <ContentLoader
              backgroundColor="var(--surface-muted)"
              foregroundColor="var(--surface-default)"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <circle cx="16" cy="16" r="16" />
            </ContentLoader>
          ) : (
            <button
              type="button"
              className={clsx('flex items-center', styles.userDropdownButton)}
              {...triggerProps}
              onClick={handleToggle}
            >
              {user.data?.firstName || user.data?.lastName ? (
                <span className={clsx('hidden mr-3 lg:block', styles.userDropdownButtonText)}>{`${
                  user.data?.firstName ?? ''
                } ${user.data?.lastName ?? ''}`}</span>
              ) : null}
              <span className="geecko-profile-layout__panel-wrapper-avatar" style={avatarStyle} />
            </button>
          )}
          <ColorThemeSwitcher />
        </div>

        {renderLayer(
          <AnimatePresence>
            {isOpen && (
              <motion.ul
                {...layerProps}
                initial={{ opacity: 0, scale: 0.85 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.85 }}
                transition={{
                  type: 'spring',
                  stiffness: 800,
                  damping: 35,
                }}
                className={styles.dropdownOptions}
              >
                {user.data ? (
                  <>
                    <div className={styles.dropdownHeading}>
                      <div className="flex items-center mr-14">
                        {user.data?.firstName && user.data.lastName ? (
                          <span>{`${user.data.firstName} ${user.data.lastName}`}</span>
                        ) : null}
                        <span className={styles.avatar} style={avatarStyle} />
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <CloseIcon size={16} />
                      </button>
                    </div>
                    <div className="h-5/6 flex flex-col justify-content-end">
                      <li className={styles.dropdownLi}>
                        <Link href="/profile">
                          <a className={styles.dropdownOption}>
                            <div
                              className={clsx(styles.menuItem, router.pathname === '/profile' && styles.menuItemActive)}
                            >
                              <ProfileIcon size={16} />
                              <span className="geecko-profile-layout__menu-wrapper-link-title geecko-profile-layout__menu-wrapper-link-title--dropdown m-l-8">
                                {t('profile.profile')}
                              </span>
                            </div>
                          </a>
                        </Link>
                      </li>
                      <li className={styles.dropdownLi}>
                        <Link href="/profile/settings">
                          <a className={styles.dropdownOption}>
                            <div
                              className={clsx(
                                styles.menuItem,
                                router.pathname === '/profile/settings' && styles.menuItemActive,
                              )}
                            >
                              <SettingsIcon size={16} />
                              <span className="geecko-profile-layout__menu-wrapper-link-title geecko-profile-layout__menu-wrapper-link-title--dropdown m-l-8">
                                {t('profile.settings.title')}
                              </span>
                            </div>
                          </a>
                        </Link>
                      </li>
                      <li className={styles.dropdownLi}>
                        <Link href="/resume/">
                          <a className={styles.dropdownOption}>
                            <div
                              className={clsx(styles.menuItem, currentSection === 'resume' && styles.menuItemActive)}
                            >
                              <ResumeIcon size={16} />
                              <span className="geecko-profile-layout__menu-wrapper-link-title geecko-profile-layout__menu-wrapper-link-title--dropdown m-l-8">
                                {t('profile.resume')}
                              </span>
                            </div>
                          </a>
                        </Link>
                      </li>
                      {shouldCertificatesPageBeVisible ? (
                        <li className={styles.dropdownLi}>
                          <Link href="/certificates">
                            <a className={styles.dropdownOption}>
                              <div
                                className={clsx(
                                  styles.menuItem,
                                  currentSection === 'certificates' && styles.menuItemActive,
                                )}
                              >
                                <CertificatesIcon size={16} />
                                <span className="geecko-profile-layout__menu-wrapper-link-title geecko-profile-layout__menu-wrapper-link-title--dropdown m-l-8">
                                  {t('profile.certificates')}
                                </span>
                              </div>
                            </a>
                          </Link>
                        </li>
                      ) : null}
                      {shouldJobsPageBeVisible ? (
                        <li className={styles.dropdownLi}>
                          <Link href="/jobs">
                            <a className={styles.dropdownOption}>
                              <div
                                className={clsx(styles.menuItem, currentSection === 'jobs' && styles.menuItemActive)}
                              >
                                <JobsIcon size={16} />
                                <span className="geecko-profile-layout__menu-wrapper-link-title geecko-profile-layout__menu-wrapper-link-title--dropdown m-l-8">
                                  {t('profile.jobs')}
                                </span>
                              </div>
                            </a>
                          </Link>
                        </li>
                      ) : null}
                      <li className={styles.dropdownLi}>
                        <Link href="/authorizations">
                          <a className={styles.dropdownOption}>
                            <div
                              className={clsx(
                                styles.menuItem,
                                currentSection === 'authorizations' && styles.menuItemActive,
                              )}
                            >
                              <KeyIcon size={16} />
                              <span className="geecko-profile-layout__menu-wrapper-link-title geecko-profile-layout__menu-wrapper-link-title--dropdown m-l-8">
                                {t('profile.authorizations.title')}
                              </span>
                            </div>
                          </a>
                        </Link>
                      </li>

                      <li className={styles.dropdownLi}>
                        <button
                          type="button"
                          className={clsx(styles.dropdownOption, styles.optionLogout)}
                          onClick={() => {
                            handleClose();
                            openLogoutDialog();
                          }}
                        >
                          <div className={styles.menuItem}>
                            <LogoutIcon width={16} height={16} />
                            <span className="geecko-profile-layout__menu-wrapper-link-title geecko-profile-layout__menu-wrapper-link-title--dropdown geecko-profile-layout__menu-wrapper-link-title--logout m-l-8">
                              {t('profile.logout')}
                            </span>
                          </div>
                        </button>
                      </li>
                    </div>
                  </>
                ) : (
                  <li className={styles.dropdownLi}>
                    <Link href="/signup">
                      <a className={styles.dropdownOption}>
                        <ProfileIcon size={16} />
                        <div className="geecko-profile-layout__menu-wrapper-link-title geecko-profile-layout__menu-wrapper-link-title--dropdown m-l-8">
                          {t('auth.signIn')}
                        </div>
                      </a>
                    </Link>
                  </li>
                )}
                <Arrow {...arrowProps} backgroundColor="var(--surface-two)" />
              </motion.ul>
            )}
          </AnimatePresence>,
        )}
      </div>
    </div>
  );
};
