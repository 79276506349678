import { FC } from 'react';

interface Props {
  size?: number;
}

export const CertificatesIcon: FC<Props> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.61252 6.50488H16.4306C16.723 6.50488 17.0033 6.63945 17.2101 6.87898C17.4168 7.11851 17.5329 7.44338 17.5329 7.78212V11.6138C17.5329 13.3076 16.9523 14.9319 15.9187 16.1295C14.8851 17.3272 13.4833 18 12.0216 18C11.2978 18 10.5812 17.8348 9.91249 17.5139C9.24382 17.193 8.63626 16.7226 8.12448 16.1295C7.09091 14.9319 6.51025 13.3076 6.51025 11.6138V7.78212C6.51025 7.44338 6.62639 7.11851 6.8331 6.87898C7.03981 6.63945 7.32018 6.50488 7.61252 6.50488V6.50488Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.44556 10.3795L12 12.934L14.5545 10.3795"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
