import axios from 'axios';
import { useTranslations } from 'next-intl';

import { isApiErrorWithErrorMessage, isApiErrorWithMessage } from '@/shared/api';

type IntlShape = ReturnType<typeof useTranslations>;

export const processNetworkError = (error: unknown, t?: IntlShape): string => {
  if (error && axios.isAxiosError(error)) {
    if (isApiErrorWithMessage(error)) {
      return error.response.data.message;
    }
    if (isApiErrorWithErrorMessage(error)) {
      return error.response.data.error;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return t ? t('error.unknown_error') : 'Произошла ошибка';
};
