import { FC } from 'react';

interface Props {
  size?: number;
}

export const JobsIcon: FC<Props> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 8.875H7C6.30964 8.875 5.75 9.43464 5.75 10.125V16.375C5.75 17.0654 6.30964 17.625 7 17.625H17C17.6904 17.625 18.25 17.0654 18.25 16.375V10.125C18.25 9.43464 17.6904 8.875 17 8.875Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 17.625V7.625C14.5 7.29348 14.3683 6.97554 14.1339 6.74112C13.8995 6.5067 13.5815 6.375 13.25 6.375H10.75C10.4185 6.375 10.1005 6.5067 9.86612 6.74112C9.6317 6.97554 9.5 7.29348 9.5 7.625V17.625"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
