import { FC } from 'react';

interface Props {
  size?: number;
}

export const ProfileIcon: FC<Props> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.6666 19.5V17.8333C18.6666 16.9493 18.3154 16.1014 17.6903 15.4763C17.0652 14.8512 16.2173 14.5 15.3333 14.5H8.66658C7.78253 14.5 6.93468 14.8512 6.30956 15.4763C5.68444 16.1014 5.33325 16.9493 5.33325 17.8333V19.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0001 11.1667C13.841 11.1667 15.3334 9.67428 15.3334 7.83333C15.3334 5.99238 13.841 4.5 12.0001 4.5C10.1591 4.5 8.66675 5.99238 8.66675 7.83333C8.66675 9.67428 10.1591 11.1667 12.0001 11.1667Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
