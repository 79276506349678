import { FC } from 'react';

interface Props {
  size?: number;
}

export const KeyIcon: FC<Props> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3333 8.99998L16.6667 6.66665M18 5.33331L16.6667 6.66665L18 5.33331ZM11.5933 11.74C11.9376 12.0796 12.2112 12.484 12.3985 12.9298C12.5858 13.3757 12.6831 13.8542 12.6847 14.3377C12.6863 14.8213 12.5923 15.3004 12.4079 15.7475C12.2236 16.1946 11.9527 16.6008 11.6108 16.9427C11.2688 17.2847 10.8626 17.5556 10.4155 17.7399C9.96846 17.9242 9.48933 18.0183 9.00575 18.0167C8.52218 18.015 8.0437 17.9178 7.59786 17.7305C7.15203 17.5432 6.74764 17.2695 6.408 16.9253C5.74009 16.2338 5.37051 15.3076 5.37886 14.3462C5.38722 13.3848 5.77284 12.4651 6.45267 11.7853C7.13249 11.1055 8.05214 10.7199 9.01353 10.7115C9.97492 10.7032 10.9011 11.0727 11.5927 11.7406L11.5933 11.74ZM11.5933 11.74L14.3333 8.99998L11.5933 11.74ZM14.3333 8.99998L16.3333 11L18.6667 8.66665L16.6667 6.66665L14.3333 8.99998Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
